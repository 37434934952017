/*------ Default -------*/

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  border: 0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(196, 205, 224, 0.2);
  border-radius: 13px;
  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }
    .list-group {
      &:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-child .list-group-item:last-child {
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
      }
    }
  }
}
.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link {
  &:hover {
    text-decoration: none;
  }
  + .card-link {
    margin-left: 1.5rem;
  }
}
.card .card-header .card-title:before {
  content: '';
  position: absolute;
  left: 0px;
  padding: 2px;
  height: 25px;
  background: $primary;
}
.card-header {
  background: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  margin-bottom: 0;
  border-bottom: $border;
  .card-title {
    margin-bottom: 0;
  }
  // &.border-0+.card-body {
  // 	padding-top: 0;
  // }
  &:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
  }
  + .list-group .list-group-item:first-child {
    border-top: 0;
  }
}
.card-footer {
  padding: 1.5rem 1.5rem;
  color: #4b5986;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: $border;
  &:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
  }
}
.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  margin: -0.75rem 0;
}
.card-img {
  width: 100%;
  border-radius: calc(3px - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px);
}
.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  > .card {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    > .card {
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
      + .card {
        margin-left: 0;
        border-left: 0;
      }
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .card-img-top,
        .card-header {
          border-top-right-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .card-img-top,
        .card-header {
          border-top-left-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-left-radius: 0;
        }
      }
      &:only-child {
        border-radius: 3px;
        .card-img-top,
        .card-header {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
      &:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
        .card-img-top,
        .card-img-bottom,
        .card-header,
        .card-footer {
          border-radius: 0;
        }
      }
    }
  }
  // .responsive-header.card-header{
  // 	display: block;
  // 	.card-title{
  // 		margin-bottom: 1rem;
  // 	}
  // 	.card-options .btn-list>.btn:not(:last-child){
  // 		margin-right: .2rem;
  // 		font-size: 10px;
  // 	}
  // }
}
/*------ Default Card Values -------*/
@media print {
  .card {
    box-shadow: none;
    border: none;
  }
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  padding: 1.5rem 1.5rem;
  position: relative;
  + .card-body {
    border-top: $border;
  }
}
.card-body > :last-child {
  margin-bottom: 0;
}
@media print {
  .card-body {
    padding: 0;
  }
}
.card-footer,
.card-bottom {
  padding: 1rem 1.5rem;
  background: none;
}
.log {
  margin-top: -26px;
}
@media print {
  .card-header {
    display: none;
  }
}
.reg {
  text-align: center;
  font-size: 50px;
  color: #2e1170;
  float: right;
}
.card-title {
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 0.01rem;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  a {
    color: inherit;
  }
  &:only-child {
    margin-bottom: 0;
  }
  small {
    color: #9aa0ac;
    font-size: 0.875rem;
    display: block;
    margin: -0.75rem 0 1rem;
    line-height: 1.1;
    font-weight: 400;
  }
}
.card-subtitle {
  color: #9aa0ac;
  font-size: 0.875rem;
  display: block;
  margin: -0.75rem 0 1rem;
  line-height: 1.1;
  font-weight: 400;
}
.card-table {
  margin-bottom: 0;
  tr {
    &:first-child {
      td,
      th {
        border-top: 0;
      }
    }
    td:first-child,
    th:first-child {
      padding-left: 1.5rem;
    }
    td:last-child,
    th:last-child {
      padding-right: 1.5rem;
    }
  }
}
.card-body + .card-table {
  border-top: $border;
}
.card-link + .card-link {
  margin-left: 1rem;
}
.card-aside {
  -ms-flex-direction: row;
  flex-direction: row;
}
.card-aside-column {
  min-width: 5rem;
  width: 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  background: no-repeat center/cover;
}
.card-options {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  margin-right: -0.5rem;
  color: #9aa0ac;
  -ms-flex-item-align: center;
  align-self: center;
  a:not(.btn) {
    color: $color;
    display: inline-block;
    min-width: 1rem;
    &:hover {
      text-decoration: none;
      color: $color;
    }
    i {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
  .dropdown-toggle:after {
    display: none;
  }
}
.legend li {
  padding: 5px;
  float: left;
}
.icon-size {
  font-size: 2.5rem !important;
}
/*Card options*/
.card-collapsed {
  > :not(.card-header):not(.card-status) {
    display: none;
  }
  .card-options-collapse i:before {
    content: '\e92d';
  }
}
.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
  .card-options-fullscreen i:before {
    content: '\e992';
  }
  .card-options-remove {
    display: none;
  }
}
/**Card status*/
.card-status {
  position: absolute;
  top: -1px;
  left: 0px;
  right: 0px;
  height: 3px;
  border-radius: 7px 7px 0 0;
  background: rgba(0, 40, 100, 0.12);
}
.card-status-left {
  right: auto;
  bottom: 0;
  height: auto;
  width: 3px;
  border-radius: 3px 0 0 3px;
}
/**Card alert*/

.card-alert {
  border-radius: 0;
  margin: -1px -1px 0;
}
.card-category {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: 0 0 0.5rem;
  background: #f3f3f3;
}
.card-custom-icon {
  position: absolute;
  right: 25px;
  top: 15px;
  color: #e5e9f2;
  width: 60px;
  height: 60px;
}
.custom-card {
  &.blue {
    color: #1687ed;
    background: #ebf5fd;
    border: 1px solid #5dacf2;
    border-radius: 8px;

    .loader {
      border-color: #99d0ff;
      border-top-color: #1687ed;
    }
  }

  &.red {
    color: #ff0000;
    background: #ffe6e6;
    border: 1px solid #ff8080;
    border-radius: 8px;

    .loader {
      border-color: #ff9696;
      border-top-color: #ff0000;
    }
  }

  &.green {
    color: #007e08;
    background: #cbffce;
    border: 1px solid #00cb0d;
    border-radius: 8px;

    .loader {
      border-color: #75cc7d;
      border-top-color: #007e08;
    }
  }
}
.custom-card {
  &.blue {
    color: #1687ed;
    background: #ebf5fd;
    border: 1px solid #5dacf2;
    border-radius: 8px;
    .loader {
      border-color: #99d0ff;
      border-top-color: #1687ed;
    }
  }
  &.red {
    color: #ff0000;
    background: #ffe6e6;
    border: 1px solid #ff8080;
    border-radius: 8px;
    .loader {
      border-color: #ff9696;
      border-top-color: #ff0000;
    }
  }
  &.green {
    color: #007e08;
    background: #cbffce;
    border: 1px solid #00cb0d;
    border-radius: 8px;
    .loader {
      border-color: #75cc7d;
      border-top-color: #007e08;
    }
  }
}
.card-blog-overlay .card-header {
  border-bottom: 1px solid $white-2;
}
.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
  border-top: 1px solid $white-2;
}
.card-blog-overlay {
  // background: url('../images/photos/thumb2.jpg');
  background-size: cover;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: $black-5;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    border-radius: 7px;
  }
}
.card-blog-overlay1:before,
.card-blog-overlay2:before {
  content: '';
  display: block;
  position: absolute;
  background: $black-5;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  border-radius: 7px;
}
.card-blog-overlay1 {
  // background: url('../images/photos/thumb3.jpg');
  background-size: cover;
  overflow: hidden;
}
.card-blog-overlay2 {
  // background: url('../images/photos/22.jpg');
  background-size: cover;
  overflow: hidden;
}
