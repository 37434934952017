.btn-primary {
  font-family: 'Inter', sans-serif;

  &:hover {
    border-color: $primary-rose-75;
  }

  &.disabled,
  &:disabled {
    background-color: $primary-rose-100;
    opacity: 75%;
    border-color: $primary-rose-100;
  }
}

.btn {
  border-radius: $b-radius-full;
}

.btn-check + .btn:hover {
  --bs-btn-bg: #{$primary-rose-100};
  --bs-btn-border-color: #{$primary-rose-100};
}

.btn-outline-primary {
  --bs-btn-active-bg: #{$primary-rose-100};
  --bs-btn-active-border-color: #{$primary-rose-100};

  font-family: 'Inter', sans-serif;
  @include font-medium();
  border: 2px solid $black-15;
  box-shadow: none;
  padding: $general-padding-small $general-padding;
  font-size: 13px;

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      background-color: $primary-rose-100;
      border-color: $primary-rose-100;
    }
  }
}

.btn-lg {
  padding: #{3 * $general-padding-x-small} #{3 * $general-padding-small};
  @include font-semibold();
  font-size: 13px;
}

.btn-icon {
  display: flex;
  align-items: center;
  padding: #{$general-padding-small} #{$general-padding};

  i {
    font-size: 19px;
    @include font-bold();
    margin-right: #{$general-margin-x-small + 2};
    line-height: 1.4;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: $black-15;
  border-color: $black-15;
  opacity: 1;
}

.btn-primary:disabled {
  opacity: 75%;
}
