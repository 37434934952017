@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
