/*--- Booststrap-styles ---*/

:root {
  --primary-bg-color: #46a7ff;
  --primary-bg-hover: #255bfb;
  --primary-bg-border: #255bfb;
  --transparent-body: #002aab;
  --primary-transparentcolor: #eaedf7;
  --darkprimary-transparentcolor: #2b356e;
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);
  --indigo: #6574cd;
  --purple: #aa4cf2;
  --pink: #ef4eb8;
  --orange: #f34932;
  --yellow: #ecb403;
  --green: #28a745;
  --teal: #17d1dc;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #6c5ffc;
  --secondary: #fe7f00;
  --success: #0dcd94;
  --info: #128af9;
  --warning: #fbc518;
  --danger: #f7284a;
  --light: #f8f9fa;
  --dark: #353a40;
  --blue: #083dc1;
  --white: #fff;
  --black: #000;
  --muted: #9ba5ca;
  --dark-bg: #25274a;
  --primary01: rgba(37, 91, 251, 0.1);
  --primary02: rgba(37, 91, 251, 0.2);
  --primary03: rgba(37, 91, 251, 0.3);
  --primary04: rgba(37, 91, 251, 0.4);
  --primary05: rgba(37, 91, 251, 0.5);
  --primary06: rgba(37, 91, 251, 0.6);
  --primary07: rgba(37, 91, 251, 0.7);
  --primary08: rgba(37, 91, 251, 0.8);
  --primary09: rgba(37, 91, 251, 0.9);
}
body * {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    transition: 0.3s;
  }
  &::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    background: #c2cbdf;
  }
  &::-ms-webkit-scrollbar-track {
    background: $white;
  }
  &::-ms-webkit-scrollbar {
    width: 5px;
    height: 5px;
    transition: 0.3s;
  }
  &::-ms-webkit-scrollbar-thumb,
  &:hover::-ms-webkit-scrollbar-thumb {
    background: #c2cbdf;
  }
  &::-ms-webkit-scrollbar-track {
    background: $white;
  }
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $color;
  text-align: left;
  background: $background;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow-y: scroll;
  // display: flex;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.66em;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 400;
}
abbr {
  &[title],
  &[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1a1630;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

  &:hover {
    text-decoration: none;
  }

  &:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:focus {
      color: inherit;
      text-decoration: none;
      outline: 0;
    }
  }
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
  display: block;
  font-size: 85%;
  color: #282d3c;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #505662;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;

  &:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
button:focus,
textarea:focus {
  outline: 0;
}
input {
  &[type='radio'],
  &[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  &[type='date'],
  &[type='time'],
  &[type='datetime-local'],
  &[type='month'] {
    -webkit-appearance: listbox;
  }
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number'] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.66em;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1,
.h1 {
  font-size: 2rem;
}
h2,
.h2 {
  font-size: 1.75rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 1rem;
}
h6,
.h6 {
  font-size: 0.875rem;
}
.lead {
  font-size: 1.171875rem;
  font-weight: 300;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ebecf1;
}
small,
.small {
  font-size: 87.5%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;

  li {
    border-bottom: 1px solid #ebecf1;
  }
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.171875rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;

  &::before {
    content: '\2014 \00A0';
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: $white;
  border: 1px solid #ebecf1;
  border-radius: 3px;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #868e96;
}
code,
kbd,
pre,
samp {
  font-family: Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
code {
  font-size: 85%;
  color: inherit;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 85%;
  color: $white;
  background-color: #343a40;
  border-radius: 3px;

  kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.wrap {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px $black-2, 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
a {
  &:focus,
  &:hover,
  &:active {
    outline: 0;
    text-decoration: none;
  }
}
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}
@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;
    &::before,
    &::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body,
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .table,
  .text-wrap table {
    border-collapse: collapse !important;
  }
  .table td,
  .text-wrap table td,
  .table th,
  .text-wrap table th {
    background-color: $white !important;
  }
  .table-bordered th,
  .text-wrap table th,
  .table-bordered td,
  .text-wrap table td {
    border: $border !important;
  }
}
.lead {
  line-height: 1.4;
}
a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: inherit;
}
strong,
b {
  font-weight: 600;
}
p,
ul,
ol {
  margin-bottom: 1em;
}
blockquote {
  margin-bottom: 1em;
  font-style: italic;
  color: #6e7687;
  padding-left: 2rem;
  border-left: 2px solid rgba(0, 40, 100, 0.12);
  p {
    margin-bottom: 1rem;
  }
  cite {
    display: block;
    text-align: right;
    &:before {
      content: 'Ã¢â‚¬â€ ';
    }
  }
}
code {
  background: #e9ebfa;
  border: $border;
  border-radius: 3px;
  padding: 3px;
}
pre code {
  padding: 0;
  border-radius: 0;
  border: none;
  background: none;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
pre {
  color: $info;
  padding: 1.5rem;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  border-left: 3px solid $primary;
  background-color: #f8fafc;
  border-radius: 3px;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
img {
  max-width: 100%;
}
.text-wrap {
  font-size: 14px;
  line-height: 1.66;
  > {
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 1em;
    }
  }
}

@media print {
  .container {
    max-width: none;
  }
}

textarea[cols] {
  height: auto;
}
