@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/@ng-select/ng-select/scss/default.theme.scss';

@import 'styles/components.scss';
@import 'styles/animations.scss';
@import 'styles/scss/style.scss';

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Montserrat', sans-serif;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-weight: 700;
// }

// h1 {
//   font-size: 1.75rem;
// }

// html {
//   font-size: 14px;

//   @media (min-width: 768px) {
//     font-size: 16px;
//   }
// }

html,
body {
  height: 100%;
}

.wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: $primary;
}

// main {
//   width: 100%;
// }

ngx-spinner div {
  &.loading-text,
  div.la-ball-pulse .loading-text {
    font-size: 2rem;
    color: #fff;
  }
}

// .button-small {
//   padding: 0.8rem 1.5rem;
// }

a.link {
  text-decoration: underline !important;
  cursor: pointer !important;

  &:hover {
    text-decoration: none !important;
  }
}

.link-arrow {
  cursor: pointer !important;
}

.text-danger {
  color: #721c24 !important;
}

// .form-control {
//   height: auto;
//   font-size: 1.3em;
// }

.main-wrapper > router-outlet + * {
  width: 100%;
}

ngb-alert p {
  margin-bottom: 0;
}
