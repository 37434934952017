/*------ Tables -----*/

.table,
.text-wrap table {
  width: 100% !important;
  max-width: 100%;
  margin-bottom: 1rem;
}
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: $border;
}
.text-wrap table {
  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: $border;
  }
}
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 0;
}
.border {
  vertical-align: top;
  border: $border;
}
.table tbody + tbody,
.text-wrap table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table,
.text-wrap table .table,
.table .text-wrap table {
  background-color: #ebeff8;
}
.text-wrap {
  .table table,
  table table {
    background-color: #ebeff8;
  }
}
.table-sm {
  th,
  td {
    padding: 0.3rem;
  }
}
.table-bordered,
.text-wrap table,
.table-bordered th,
.text-wrap table th,
.table-bordered td,
.text-wrap table td {
  border: $border;
}
.table-bordered thead th,
.text-wrap table thead th,
.table-bordered thead td,
.text-wrap table thead td {
  border-bottom-width: 2px;
}
.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}
.table-striped tbody tr:nth-of-type(odd),
.table-hover tbody tr:hover {
  background-color: #f3f5fb;
}
.table-hover tbody tr:hover {
  background-color: #f3f5fb !important;
  cursor: pointer;
}
.table-primary {
  background-color: rgba(51, 102, 255, 0.1);
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: rgba(51, 102, 255, 0.1);
    }
  }
}
// .table > :not(:last-child) > :last-child > * {
//     border-bottom-color: inherit;
// }
.table-primary.table-vcenter {
  th,
  td {
    border-color: rgba(68, 84, 195, 0.17) !important;
  }
}
.table-hover .table-primary:hover {
  background-color: #ebeff8;
  > {
    td,
    th {
      background-color: #b7cded;
    }
  }
}
.table tr.selected {
  background-color: #f3f5fb;
}
.table-secondary {
  background-color: $secondary;
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: $secondary;
    }
  }
}
.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
  > {
    td,
    th {
      background-color: $secondary;
    }
  }
}
.table-success {
  background-color: #d2ecb8;
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: #d2ecb8;
    }
  }
}
.table-hover .table-success:hover {
  background-color: #c5e7a4;
  border-radius: 0 0 5px 5px;
  > {
    td,
    th {
      background-color: #c5e7a4;
    }
  }
}
.table-info {
  background-color: #cbe7fb;
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: #cbe7fb;
    }
  }
}
.table-hover .table-info:hover {
  background-color: #b3dcf9;
  > {
    td,
    th {
      background-color: #b3dcf9;
    }
  }
}
.table-warning {
  background-color: #fbeebc;
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: #fbeebc;
    }
  }
}
.table-warning.table-vcenter {
  th,
  td {
    border-color: rgba(236, 180, 3, 0.17) !important;
  }
}
.table-hover .table-warning:hover {
  background-color: #fae8a4;
  > {
    td,
    th {
      background-color: #fae8a4;
    }
  }
}
.table-danger {
  background-color: #f1c1c0;
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: #f1c1c0;
    }
  }
}
.table-hover .table-danger:hover {
  background-color: #ecacab;
  > {
    td,
    th {
      background-color: #ecacab;
    }
  }
}
.table-light {
  background-color: #fdfdfe;
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: #fdfdfe;
    }
  }
}
.table-hover .table-light:hover {
  background-color: #ececf6;
  > {
    td,
    th {
      background-color: #ececf6;
    }
  }
}
.table-dark {
  background-color: #c6c8ca;
  border-radius: 0 0 5px 5px;
  > {
    th,
    td {
      background-color: #c6c8ca;
    }
  }
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
  > {
    td,
    th {
      background-color: #b9bbbe;
    }
  }
}
.table-active {
  background-color: rgba(0, 0, 0, 0.04);
  > {
    th,
    td {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.04);
  > {
    td,
    th {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
.table .thead-dark th,
.text-wrap table .thead-dark th {
  color: #ebeff8;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th,
.text-wrap table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #ebeff8;
  background-color: #212529;
  th,
  td,
  thead th {
    border-color: #32383e;
  }
  &.table-bordered {
    border: 0;
  }
}
.text-wrap table.table-dark {
  border: 0;
}
.table-dark {
  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: $white-05;
  }
  &.table-hover tbody tr:hover {
    background-color: $white-75;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table-bordered {
      border: 0;
    }
  }
  .text-wrap .table-responsive-sm > table {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table-bordered {
      border: 0;
    }
  }
  .text-wrap .table-responsive-md > table {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table-bordered {
      border: 0;
    }
  }
  .text-wrap .table-responsive-lg > table {
    border: 0;
  }
}
@media (max-width: 1279.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table-bordered {
      border: 0;
    }
  }
  .text-wrap .table-responsive-xl > table {
    border: 0;
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    > .table-bordered {
      border: 1px solid #e9ebfa;
    }
  }
}
.text-wrap .table-responsive > table {
  border: 0;
}
.table thead th,
.text-wrap table thead th {
  vertical-align: bottom;
  border-bottom: $border;
  // border-top: 0;
  border-bottom-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.table th,
.text-wrap table th {
  color: #313e6a;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 400;
}
.table-md {
  th,
  td {
    padding: 0.5rem;
  }
}
.table-vcenter {
  td,
  th {
    vertical-align: middle;
    border-top: $border;
  }
}
.table-center {
  td,
  th {
    text-align: center;
  }
}
.table-striped tbody tr {
  &:nth-of-type(odd) {
    background: transparent;
  }
  &:nth-of-type(even) {
    background-color: #f3f5fb;
  }
}
.table-calendar {
  margin: 0 0 0.75rem;
  td,
  th {
    border: 0;
    text-align: center;
    padding: 0 !important;
    width: 14.28571429%;
    line-height: 2.5rem;
  }
  td {
    border-top: 0;
  }
}
.table-calendar-link {
  line-height: 2rem;
  min-width: calc(2rem + 2px);
  display: inline-block;
  border-radius: 3px;
  background: #f8f9fa;
  color: #495057;
  font-weight: 600;
  transition: 0.3s background, 0.3s color;
  position: relative;
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    border-radius: 50px;
    background: #467fcf;
  }
  &:hover {
    color: $white;
    text-decoration: none;
    background: #467fcf;
    transition: 0.3s background;
    &:before {
      background: $white;
    }
  }
}
.table-header {
  cursor: pointer;
  transition: 0.3s color;
  &:hover {
    color: #495057 !important;
  }
  &:after {
    content: '\f0dc';
    font-family: FontAwesome;
    display: inline-block;
    margin-left: 0.5rem;
    font-size: 0.75rem;
  }
}
.table-header-asc {
  color: #495057 !important;
  &:after {
    content: '\f0de';
  }
}
.table-header-desc {
  color: #495057 !important;
  &:after {
    content: '\f0dd';
  }
}
.table.dataTable.no-footer {
  border-bottom: 0;
}

table.dataTable tbody th,
table.dataTable tbody td,
table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 22px !important;
}
.dt-button-collection .dt-button.dropdown-item.active {
  color: $color;
}
@media (max-width: 767px) {
  table img {
    max-width: inherit;
  }
  .table_img img {
    max-width: 100%;
  }
}
.table > :not(caption) > * > * {
  background-color: inherit;
  border-bottom-width: inherit;
  box-shadow: inherit;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-style: none;
}
.parent {
  animation: none !important;
}
table.table-bordered.dataTable thead tr:first-child th,
table.table-bordered.dataTable thead tr:first-child td {
  border-top-width: 0px !important;
}
