/*----- Avatars -----*/

.country-selector .avatar {
  width: 1.4rem;
  height: 1.2rem;
}
.avatar {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  position: relative;
  text-align: center;
  display: inline-block;
  background: $primary no-repeat center/cover;
  color: $white;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noborder {
  border-radius: 0;
}
.brround {
  border-radius: 50%;
}
.bradius {
  border-radius: 25%;
}
.avatar i {
  font-size: 125%;
  vertical-align: sub;
}
.avatar-status {
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid $white;
  background: #868e96;
  border-radius: 50%;
}
.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
}
.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1rem;
}
.avatar-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.25rem;
}
.avatar-xl {
  width: 4rem !important;
  height: 4rem !important;
  line-height: 4rem;
  font-size: 1.75rem;
}
.avatar-xxl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 2rem;
}
.avatar-placeholder {
  background: #ced4da
    url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134"><path fill="#868e96" d="M65.92 66.34h2.16c14.802.42 30.928 6.062 29.283 20.35l-1.618 13.32c-.844 6.815-5.208 7.828-13.972 7.866H52.23c-8.764-.038-13.13-1.05-13.973-7.865l-1.62-13.32C34.994 72.4 51.12 66.76 65.92 66.34zM49.432 43.934c0-9.82 7.99-17.81 17.807-17.81 9.82 0 17.81 7.99 17.81 17.81 0 9.82-7.99 17.807-17.81 17.807-9.82 0-17.808-7.987-17.808-17.806z"/></svg>')
    no-repeat (center / 80%);
}
.avatar-list {
  margin: 0 0 -0.5rem;
  padding: 0;
  font-size: 0;
  .avatar {
    margin-bottom: 0.5rem;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
.avatar-list-stacked .avatar {
  margin-right: -0.9em !important;
  box-shadow: 0 0 0 2px $white;
}
.avatar-blue {
  background-color: $blue;
  color: #467fcf;
}
.avatar-indigo {
  background-color: #d1d5f0;
  color: $indigo;
}
.avatar-purple {
  background-color: #e4cff9;
  color: $purple;
}
.avatar-pink {
  background-color: #fcd3e1;
  color: $pink;
}
.avatar-red {
  background-color: #f0bcbc;
  color: #dc0441;
}
.avatar-orange {
  background-color: #fee0c7;
  color: $orange;
}
.avatar-yellow {
  background-color: #fbedb7;
  color: $warning;
}
.avatar-green {
  background-color: #cfeab3;
  color: $success;
}
.avatar-teal {
  background-color: #bfefea;
  color: $teal;
}
.avatar-cyan {
  background-color: #b9e3ea;
  color: #17a2b8;
}
.avatar-white {
  background-color: white;
  color: $white;
}
.avatar-gray {
  background-color: #dbdde0;
  color: #868e96;
}
.avatar-gray-dark {
  background-color: #c2c4c6;
  color: $dark;
}
.avatar-azure {
  background-color: #c7e6fb;
  color: $info;
}
.avatar-lime {
  background-color: #d7f2c2;
  color: #7bd235;
}
.product-price {
  font-size: 1rem;
  strong {
    font-size: 1.5rem;
  }
}
@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  100%,
  60% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  100%,
  60% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  100%,
  60% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  100%,
  60% {
    left: 107%;
    right: -8%;
  }
}
