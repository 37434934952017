@media (min-width: 992px) {
  .app.sidenav-toggled {
    .app-content {
      margin-left: 0;
    }
    .app-sidebar {
      left: 270px;
    }
    .app-sidebar__overlay {
      visibility: hidden;
    }
  }
}
@media (max-width: 991px) {
  .app {
    overflow-x: hidden;
    .app-sidebar {
      left: -270px;
      .app-sidebar3 {
        margin-top: 0;
      }
    }
    .app-sidebar__overlay {
      visibility: hidden;
    }
    &.sidenav-toggled {
      .app-content {
        margin-left: 0;
      }
      .app-sidebar {
        left: 0;
      }
      .app-sidebar__overlay {
        visibility: visible;
      }
    }
    &.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
      line-height: 1;
    }
    .side-menu {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
.app-sidebar .ps__thumb-y {
  right: 0;
}
.app-content {
  margin-top: 0px;
  overflow: hidden;
  -webkit-transition: margin-left 0.2s ease;
  -o-transition: margin-left 0.2s ease;
  transition: margin-left 0.2s ease;
  .side-app {
    // padding: 25px 1.5rem 0 1.5rem;
  }
}
.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .app-content {
    margin-left: 270px;
    padding-bottom: 0rem;
  }
}
@media (max-width: 991.98px) {
  .app-content {
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .app-sidebar__toggle {
    padding: 10px !important;
    line-height: 0.2 !important;
  }
  .app-sidebar__toggle a i {
    margin-top: -3px !important;
  }
}
@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: $white;
  }
}
.light-text {
  font-weight: 300 !important;
}
.semibold-text {
  font-weight: 600 !important;
}
.line-head {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
/*----- Componant: Top Navigation Bar ----- */

.app-header {
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  padding-right: 15px;
  padding: 1.2rem 0;
  -webkit-transition: padding-left 0.2s ease;
  -o-transition: padding-left 0.2s ease;
  transition: padding-left 0.2s ease;
  border-bottom: 1px solid #ebedfa;
  background: $white;
  box-shadow: 0px 6px 18px rgba(196, 205, 224, 0.1);
}
@media (min-width: 768px) {
  .app-header {
    padding-right: 13px;
  }
}
@media print {
  .app-header {
    display: none;
  }
}
.app-header__logo {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  color: $white;
  text-align: center;
  font-family: 'Niconne';
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
@media (min-width: 992px) {
  .app-header__logo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: block;
    width: 270px;
  }
}
.app-sidebar__toggle {
  a {
    color: #555b95;
  }
  margin: 0 6px;
  padding: 8px;
  text-align: center;
  position: relative;
  border-radius: 8px;
  line-height: 2;
  background: #f1f4fb;
  justify-content: center;
  height: 45px;
  width: 45px;
  margin-right: 1.3rem;
  &:hover {
    color: $primary;
  }
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
@media (max-width: 991px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}
.app-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .app-nav {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #a8a8a8;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  &:hover,
  &:focus {
    background: $black-1;
    color: #f6f6f6;
  }
}
.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #17263a;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 6px 18px rgba(196, 205, 224, 0.2);
  -webkit-transition: left 0.2s ease, width 0.2s ease;
  transition: left 0.2s ease, width 0.2s ease;
  //transition: left 0.2s ease, width 0.2s ease;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: $black-2;
  }
}
.sidebar-mini .app-sidebar {
  width: 270px;
}
.app-sidebar3 {
  height: 100%;
}
@media print {
  .app-sidebar {
    display: none;
  }
}
@media (max-width: 991px) {
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}
.app-sidebar__user {
  .dropdown-menu {
    top: 10px !important;
  }
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #aab3cc;
  width: 100%;
  padding: 25px 20px;
  display: inline-block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.app-sidebar .side-item.side-item-category {
  color: $muted;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  padding: 0 20px 0 18px;
  opacity: 0.4;
  &:not(:first-child) {
    margin-top: 25px;
    margin-bottom: 10px;
  }
}
.sidenav-toggled {
  .app-sidebar__user .user-pic {
    margin: 0px 0px 10px 0;
    &::before {
      display: none;
    }
    .avatar-xxl {
      border: 3px solid #2c4669;
    }
  }
}
.sidenav-toggled.sidenav-toggled1 {
  .app-sidebar__user .user-pic {
    &::before {
      display: block;
    }
  }
}
.app-sidebar__user .user-pic {
  margin: 0px 0px 10px 0;
  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 37%;
    bottom: 8px;
    width: 0.75rem;
    height: 0.75rem;
    border: 2px solid #fff;
    border-radius: 50%;
    background: $success;
    cursor: pointer;
  }
}
.app-sidebar__user .user-pic .avatar-xxl {
  border: 3px solid #2c4669;
}
.app-sidebar__user-avatar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 15px;
}
.app-sidebar__user-name.text-muted {
  &.text-sm {
    font-size: 15px;
    font-weight: 400;
  }
  font-size: 17px;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: $muted !important;
  font-size: 15px;
  margin-top: 5px !important;
}
.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 600;
  color: #e5e9ec;
  font-size: 15px;
  margin-top: 5px !important;
}
.side-menu {
  padding-bottom: 40px;
  padding: 10px;
}
.side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 15px 12px 15px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
  margin: 5px 0;
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary;
  }
  &.active .side-menu__icon,
  &:hover .side-menu__icon,
  &:focus .side-menu__icon {
    color: $primary;
  }
}
@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled .side-menu__label {
    display: none !important;
    position: relative;
  }
  .slide-menu a.active {
    color: $primary !important;
    background: transparent;
  }
  .sticky {
    width: 100%;
  }
}
.slide-item {
  &.active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary !important;
    background: transparent;
  }
}
.slide.is-expanded a {
  color: #eceff9;
  text-decoration: none;
  &.slide-item {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      text-decoration: none;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
}
.side-menu .side-menu__icon {
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  margin-right: 8px;
  margin-left: -8px;
}
.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}
.side-menu__label {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: 400;
}
.slide.is-expanded {
  .slide-menu {
    li a:hover {
      color: $primary;
    }
    display: block;
  }
  .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
.slide-menu {
  display: none;
  padding-left: 10px;
  font-size: 13.2px !important;
}
.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 14px 8px 35px;
  color: #eceff9;
  border-radius: 7px;
  .icon {
    margin-right: 5px;
  }
}
.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.app-sidebar__user .user-info h5 {
  color: $white;
  font-weight: 500;
  margin-bottom: 3px !important;
}
@media (min-width: 992px) {
  .sidebar-mini.sidenav-toggled {
    .side-menu .sidemenu_icon {
      margin-right: 0;
    }
    .Annoucement_card,
    .nav-pills-circle,
    .app-user-rating {
      display: none !important;
    }
    .green_icon {
      right: 12px;
      bottom: 5px;
    }
    .app-sidebar__user-name,
    .app-sidebar__user-designation,
    .angle,
    .app-sidebar__user-name,
    .user-notification,
    .app-sidebar__user-name,
    .user-info {
      display: none;
    }
    .sidebar-mini.sidenav-toggled.user-notification::before {
      background: transparent;
      display: none;
    }
    > .avatar-xl {
      width: 3rem !important;
      height: 3rem !important;
      line-height: 3rem;
      font-size: 1rem;
      margin-bottom: 0px !important;
    }
    .app-sidebar__user {
      top: 0.5rem;
      right: 30px !important;
      margin-top: 0px;
    }
    .side-menu {
      margin-top: 0px;
    }
    .app-sidebar__user-avatar {
      width: 25px;
      height: 25px;
    }
    .app-sidebar__user {
      padding: 12px 0px 12px 0;
      margin-bottom: 0px;
    }
    .profile-img {
      top: 0px;
      right: 19px;
    }
    .app-content {
      margin-left: 70px;
    }
    .app-sidebar {
      left: 0;
      width: 70px;
      overflow: hidden;
      -webkit-transition: left 0.3s ease, width 0.3s ease;
      transition: left 0.3s ease, width 0.3s ease;
    }
    .app-sidebar__user .user-pic .avatar-xxl {
      width: 2.8rem;
      height: 2.8rem;
    }
    .side-item-category {
      display: none;
    }
    &.sidenav-toggled1 .app-sidebar {
      overflow: visible;
    }
    .side-menu__item {
      &:hover {
        overflow: visible;
      }
    }
    .slide {
      &:hover {
        .slide-menu {
          max-height: 100%;
          opacity: 1;
          visibility: visible;
          z-index: 10;
        }
      }
    }
    .slide-menu {
      position: absolute;
      opacity: 0;
      border-bottom-right-radius: 4px;
      z-index: 9;
      visibility: hidden;
      -webkit-transition: visibility 0.3s ease;
      -o-transition: visibility 0.3s ease;
      transition: visibility 0.3s ease;
    }
  }
  .app.sidebar-mini.sidenav-toggled {
    .side-menu__item {
      display: block;
    }
    .side-menu_label {
      display: block;
      font-size: 12px;
    }
    .side-menu__label {
      display: block;
      position: relative;
    }
  }
  .sidebar-mini.sidenav-toggled .app-sidebar__user .avatar-md {
    margin: 0 auto;
  }
  .app.sidebar-mini.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
    width: 270px;
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
    .app-sidebar__user {
      .dropdown-menu {
        top: 10px !important;
      }
      display: inline-block;
      align-items: center;
      color: #8e9cad;
      padding: 25px 20px;
      display: inline-block;
    }
    .side-menu .sidemenu_icon {
      margin-right: 0.6rem;
    }
    .side-item-category {
      display: block;
    }
    .app-sidebar__user-avatar {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-right: 15px;
      display: inline-block;
    }
    .app-sidebar__user-name {
      &.text-sm {
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
        width: 100%;
        height: auto;
      }
      font-size: 17px;
      display: inline-block;
      line-height: 1.3;
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      margin-bottom: 0;
      overflow: hidden;
      font-weight: 600;
      color: #e5e9ec;
      font-size: 15px;
      margin-top: 5px !important;
      display: inline-block;
    }
    .side-menu__item {
      position: relative;
      display: flex !important;
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .slide.is-expanded a {
      /*color: #aab3cc;*/
      text-decoration: none;
    }
    .side-menu .side-menu__icon {
      margin-right: 8px;
    }
    .side-menu__icon {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25px;
    }
    .side-menu__label {
      display: inline-flex !important;
      position: unset;
      min-width: inherit;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      display: inline-block;
    }
    .slide.is-expanded {
      .slide-menu {
        display: block;
      }
      .angle {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    .angle {
      display: inline-block;
    }
    .slide-menu {
      display: none;
      position: unset;
      top: inherit;
      min-width: 0;
      width: 100%;
      opacity: inherit;
      visibility: inherit;
    }
    .slide-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .icon {
        margin-right: 5px;
      }
    }
    .angle {
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      -o-transition: transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    }
    .app-sidebar__user img {
      width: 5rem !important;
      height: 5rem !important;
    }
    .green_icon {
      right: 80px;
      bottom: 10px;
    }
    .user-info,
    .app-user-rating {
      display: block !important;
    }
    .user-pic {
      margin-bottom: 0.25rem;
      margin: 0px 0px 15px 0;
    }
  }
  .app-sidebar__user-designation {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin-bottom: 0;
    overflow: hidden;
    font-weight: 600;
    color: #e5e9ec;
    font-size: 15px;
    margin-top: 5px !important;
    display: inline-block;
  }
  .side-menu__item {
    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary;
    }
  }
  .sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu__item.active .side-menu__icon {
    color: $primary;
  }
  .side-menu__item {
    &:hover .side-menu__icon,
    &:focus .side-menu__icon {
      color: $primary;
    }
  }
  .side-menu__item:hover {
    .side-menu__label,
    .side-menu__icon,
    .angle {
      color: $white;
    }
  }
  .slide-item {
    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary;
      background: transparent;
    }
  }
}
.app.sidebar-mini {
  &.sidenav-toggled {
    &.sidenav-toggled1 .app-sidebar .side-menu__icon {
      padding: 0px !important;
    }
    .side-menu .side-menu__icon {
      /*margin-right:0;*/
      margin-left: 0;
      text-align: center;
      height: 2.5rem;
      font-size: 1.2rem;
      position: relative;
      border-radius: 3px;
      border: 0px solid #e9ebfa;
      border-radius: 3px;
    }
  }
  .side-menu_label {
    display: none;
  }
}
@media (min-width: 767px) {
  .app.sidebar-mini {
    &.sidenav-toggled {
      .side-menu .side-menu__icon {
        margin-right: 0;
      }
    }
  }
}
.sidenav-toggled .app-sidebar__user .avatar-md {
  line-height: 2rem;
  font-size: 1rem;
}
.slide-menu a:before {
  content: '○';
  margin-right: 16px;
}
.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
  .nav-badge {
    position: absolute;
    top: 5px;
    left: 57px;
    display: block !important;
    padding: 3px 5px !important;
  }
  .nav-badge1 {
    display: none;
  }
}
.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}
@media (max-width: 575.98px) {
  .app-header .header-brand-img.mobile-logo {
    margin-left: 0;
  }
  .app-sidebar__toggle a i {
    line-height: 1.5;
  }
  // .app .app-sidebar {
  // 	margin-top: 83px;
  // }
  .app-content .side-app {
    padding: 25px 0.75rem 0 0.75rem;
  }
}
@media (max-width: 992px) and (min-width: 576px) {
  .app-header {
    .header-brand-img.desktop-lgo,
    .header-brand {
      margin-left: 0;
    }
  }
}
@media (max-width: 992px) {
  .header-brand {
    margin: 0 auto;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .footer {
    padding: 1.25rem 1.25rem;
  }
  .sidebar-mini .footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 270px;
  }
  .sidenav-toggled footer.footer {
    padding: 1.25rem 1.25rem 1.25rem 70px;
  }
}
@media (max-width: 768px) and (min-width: 481px) {
  .header-brand {
    margin-left: 0;
    min-width: auto !important;
  }
}
@media (max-width: 991px) {
  .app-sidebar__logo {
    display: none;
  }
  .app-sidebar__user .user-pic {
    &::before {
      display: block !important;
    }
  }
}
@media (min-width: 992px) {
  .app-header .header-brand {
    display: none;
  }
  .app-header {
    padding-left: 270px;
  }
  .sidenav-toggled {
    .app-header {
      padding-left: 70px;
    }
    .app-sidebar__logo {
      width: 60px;
      padding: 0.4rem 10px;
      left: 5px;
      height: 52px;
    }
  }
}
.app-sidebar__logo {
  padding: 1.2rem 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: 80px;
  text-align: center;
  position: fixed;
  width: 270px;
  transition: left 0.2s ease, width 0.2s ease;
}
.close-toggle {
  display: none;
}
.app-sidebar__toggle a i {
  line-height: 1.3;
  margin-top: 0;
  font-size: 22px;
}
.sidenav-toggled {
  .close-toggle {
    display: block;
  }
  .open-toggle {
    display: none;
  }
}
@media (min-width: 992px) {
  .app-sidebar__logo .header-brand-img {
    &.desktop-lgo,
    &.darkmobile-logo,
    &.mobile-logo {
      display: none;
    }
  }
  .sidenav-toggled {
    .header-brand-img {
      &.dark-logo {
        display: none;
      }
      &.darkmobile-logo {
        display: block;
        margin-top: 5px;
      }
    }
    &.sidenav-toggled1 .header-brand-img {
      &.darkmobile-logo {
        display: none;
      }
      &.dark-logo {
        display: block;
        margin: 0 auto;
        margin-top: 5px;
      }
    }
  }
  .app-sidebar {
    &.app-sidebar3 {
      margin-top: 88px;
    }
    &.app-sidebar2 {
      box-shadow: none;
      z-index: 9999;
      height: 88px;
    }
  }
  .sidenav-toggled {
    .app-sidebar-help .help-dropdown {
      display: none;
    }
    &.sidenav-toggled1 .app-sidebar-help .help-dropdown {
      display: flex;
    }
    .app-sidebar-help {
      width: 70px;
      padding: 8px 13px;
    }
    &.sidenav-toggled1 .app-sidebar-help {
      width: 270px;
    }
  }
}
.app-sidebar .app-sidebar3 {
  margin-top: 88px;
  padding-bottom: 88px;
}
.app-sidebar-help {
  position: fixed;
  bottom: 0;
  border-top: $border;
  background: #f7f8fb;
  z-index: 11;
  width: 270px;
  padding: 8px 13px;
  .header-icon {
    width: 30px;
    height: 30px;
    color: $primary;
    fill: #8e98db;
  }
}
/*-- Subslide ---*/
.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 8px 0 8px 55px;
}
.sub-slide-menu {
  display: none;
  padding: 0;
  font-size: 13px !important;
  padding-left: 0;
  list-style: none;
}
.slide-menu .sub-slide.active.is-expanded {
  background: rgba(255, 255, 255, 0.09);
}
.sub-slide.is-expanded .sub-slide-menu {
  display: block;
}
.sub-side-menu__item {
  padding: 8px 35px 8px 35px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sub-angle {
  float: right;
  line-height: 40px;
  margin-top: 3.5px;
  right: -15px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.sub-slide.is-expanded .sub-angle {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sub-slide .sub-side-menu__label {
  flex: 1 1 auto;
}

/*-- Subslide2 ---*/
.sub-slide-item2 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 8px 0 8px 75px;
  font-size: 12.5px;
}
.sub-slide-menu2 {
  display: none;
  padding: 0;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}
.slide-menu .sub-slide2.active.is-expanded {
  background: rgba(255, 255, 255, 0.09);
}
.sub-slide2.is-expanded .sub-slide-menu2 {
  display: block;
}
.sub-side-menu__item2 {
  padding: 10px 20px 10px 55px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sub-angle2 {
  float: right;
  line-height: 40px;
  margin-top: 3.5px;
  right: -15px;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.sub-slide2.is-expanded .sub-angle2 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sub-slide2 .sub-side-menu__label2 {
  font-size: 13px;
  flex: 1 1 auto;
}
.app-sidebar .dropdown-menu {
  box-shadow: 0px 10px 10px 20px rgba(16, 22, 58, 0.02), 10px 10px 15px -5px rgba(16, 22, 58, 0.08);
  // background: $primary;
  // color: $white;
  border: 0;
  .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
  a {
    // color: $white;
    &.text-primary {
      // color: $white-8 !important;
    }
  }
}

.slide .side-menu__item.active {
  color: $white !important;
  background: $primary !important;
}
.sidenav-toggled.sidenav-toggled1 {
  .app-sidebar__logo {
    width: 260px;
  }
  .side-menu {
    padding: 10px;
  }
}
.side-menu .slide a {
  color: #aab3cc;
  background: none;
}
.side-menu .sidemenu_icon {
  font-size: 1.05rem;
  color: #aab3cc;
  margin-right: 0.6rem;
}
.slide a.active .sidemenu_icon,
.side-menu__item:hover .sidemenu_icon {
  color: $white;
}
.app-sidebar .ps__rail-y {
  top: 0px !important;
}
.default-menu .side-menu {
  margin-inline: 0 !important;
}
@media (min-width: 992px) {
  .sidenav-toggled.sidenav-toggled1 .app-sidebar .nav-pills-circle,
  .sidenav-toggled.sidenav-toggled1 .emp_details {
    display: flex !important;
  }
  .sidenav-toggled.sidenav-toggled1 .Annoucement_card {
    display: block !important;
  }
  .rtl.sidenav-toggled .app-header {
    padding-right: 70px;
    padding-left: inherit;
  }
  .rtl.sidebar-mini.sidenav-toggled .app-content {
    margin-right: 70px;
    margin-left: inherit;
  }
  .rtl.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar .side-menu .sidemenu_icon {
    margin-left: 0.6rem;
  }
  .sidebar-mini .main-menu .slide-left,
  .sidebar-mini .main-menu .slide-right {
    display: none;
  }
}
.rtl .side-menu .sidemenu_icon {
  margin-left: 0.6rem !important;
  margin-right: inherit;
}
.side-menu-label1 {
  display: none;
}
.rtl {
  .slide-menu {
    padding-right: 10px;
    padding-left: inherit;
  }
  .sub-slide-item {
    padding: 8px 55px 8px 0px;
  }
  .slide-menu a:before {
    margin-left: 10px;
    margin-right: inherit;
  }
  .app-sidebar__user .user-pic::before {
    left: 37%;
    right: inherit;
  }
  @media (max-width: 991px) {
    &.app.sidenav-toggled .app-sidebar {
      right: 0;
      left: inherit;
    }
  }
}
@media (max-width: 991.98px) {
  .sidenav-toggled.main-navbar-show .main-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }
}
.main-navbar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  width: auto !important;
}
