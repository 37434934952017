@import 'variables';

// flex
@mixin flex-column($flex: 1, $overflow: auto) {
  display: flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-flow: column;
  flex: $flex 0 auto;
  overflow: $overflow;
}

@mixin flex-row($flex: 1) {
  display: flex;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-flow: row;
  flex: $flex 0 auto;
}

@mixin flex-center() {
  justify-content: center;
  align-items: center;
}

@mixin flex-1() {
  flex: 1 0 auto;
}

@mixin font-bold() {
  font-weight: 700;
}

@mixin font-semibold() {
  font-weight: 600;
}

@mixin font-medium() {
  font-weight: 500;
}

@mixin font-regular() {
  font-weight: 400;
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin pointer() {
  cursor: pointer;
}
