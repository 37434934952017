/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   Dayone - Multipurpose Admin & Dashboard Template
Version        :   V.1
Create Date    :   23/04/2021
Copyright      :   Spruko Technologies Private Limited
Author         :   Spruko
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

@import 'variables';

/* ###### Fonts ####### */
@import 'custom/fonts/fonts';

/* ###### bootstrap ####### */
@import 'bootstrap/accordion';
@import 'bootstrap/alerts';
@import 'bootstrap/badges';
@import 'bootstrap/bootstrap-styles';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/buttons';
@import 'bootstrap/cards';
@import 'bootstrap/carousel';
@import 'bootstrap/dropdown';
@import 'bootstrap/input-group';
@import 'bootstrap/jumbotron';
@import 'bootstrap/labels';
@import 'bootstrap/list';
@import 'bootstrap/media';
@import 'bootstrap/modal';
@import 'bootstrap/navigation';
@import 'bootstrap/pagination';
@import 'bootstrap/panel';
@import 'bootstrap/progress';
@import 'bootstrap/tables';
@import 'bootstrap/tags';
@import 'bootstrap/thumbnail';
@import 'bootstrap/tooltip&popover';
@import 'bootstrap/typhography';

/* ###### custom ####### */
@import 'custom/avatars';
@import 'custom/custom-control';
@import 'custom/custom-styles';
@import 'custom/custom';
@import 'custom/feather-icons';
@import 'custom/highlight';
@import 'custom/pricing';
@import 'custom/supportdashboard';

/* ###### layouts ####### */
@import 'layouts/footer';
@import 'layouts/grid';
@import 'layouts/header';
@import 'layouts/horizontal-menu';
@import 'layouts/rtl';
@import 'layouts/support-hor';
@import 'layouts/boxed';
@import 'layouts/dark';
// @import 'layouts/transparent-style';
@import 'layouts/skin-modes';

// /* ###### plugins ####### */
@import 'plugins/p-scrollbar';
@import 'plugins/sidebar';

/* ###### library ####### */
@import 'sidemenu/closed-menu';
@import 'sidemenu/default-menu';
@import 'sidemenu/hover-submenu';
@import 'sidemenu/hover-submenu1';
@import 'sidemenu/icon-text';

/* ###### utlities ####### */
@import 'utlities/alignments';
@import 'utlities/backgrounds';
@import 'utlities/borders';
@import 'utlities/height';
@import 'utlities/margin';
@import 'utlities/padding';
@import 'utlities/typography';
@import 'utlities/width';
