.light-menu {
  .app-sidebar {
    background: #ffffff;

    .side-menu .slide a.active {
      background: $primary-rose-15 !important;
    }

    .side-menu .slide a {
      color: $black-100 !important;
    }

    .side-menu .sidemenu_icon {
      color: $black-50 !important;
    }

    .side-menu .slide a:hover {
      color: $black-100 !important;
      background: $primary-rose-15 !important;
    }
  }

  .slide .side-menu__item:hover i {
    color: $black-100 !important;
  }

  .slide .side-menu__item:hover .side-menu__label {
    color: $black-100 !important;
  }

  @media (min-width: 992px) {
    .app-sidebar .app-sidebar__logo .header-brand-img.desktop-lgo {
      margin: 0;
    }

    .app-sidebar__logo {
      padding: 0 0.7rem 0.7rem;
    }
  }
}

.sidebar-mini.light-menu .slide .side-menu__item.active i,
.light-menu .slide .side-menu__item.active:hover i,
.sidebar-mini.light-menu .slide .side-menu__item.active .side-menu__label,
.light-menu .slide .side-menu__item.active:hover .side-menu__label {
  color: $primary-rose-dark !important;
  @include font-bold();
}

.header-brand-img:not(.mobile-logo) {
  height: 55px;
}

.side-menu__item {
  border-radius: $b-radius-full;
}
