/*-----Alerts-----*/

.alert {
  font-size: 0.9375rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
  &:first-child {
    margin-bottom: 1rem !important;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 500;
  &:hover,
  a:hover {
    text-decoration: underline;
  }
}
.alert-dismissible {
  padding-right: 3.90625rem;
}
hr.message-inner-separator {
  clear: both;
  margin-top: 10px;
  margin-bottom: 13px;
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}
.alert-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;
  hr {
    border-top-color: $primary;
  }
}
.alert-secondary {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;
  hr {
    border-top-color: $secondary;
  }
  .alert-link {
    color: $secondary;
  }
}
.alert-success {
  color: $white;
  background-color: $success;
  border-color: $success;
  hr {
    border-top-color: $success;
  }
  .alert-link {
    color: #022b1f;
  }
}
.alert-info {
  color: $white;
  background-color: $info;
  border-color: $info;
  hr {
    border-top-color: $info;
  }
  .alert-link {
    color: #123046;
  }
}
.alert-warning {
  color: $black;
  background-color: $warning;
  border-color: $warning;
  hr {
    border-top-color: $warning;
  }
  .alert-link {
    color: #2f2102;
  }
}
.alert-danger {
  color: $white;
  background-color: $danger;
  border-color: $danger;
  hr {
    border-top-color: $danger;
  }
  .alert-link {
    color: #2b040c;
  }
}
.alert-light {
  color: $color;
  background-color: #fefefe;
  border-color: #fdfdfe;
  hr {
    border-top-color: #ececf6;
  }
  .alert-link {
    color: #686868;
  }
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  hr {
    border-top-color: #b9bbbe;
  }
  .alert-link {
    color: #040505;
  }
}
.alert-icon {
  padding-left: 3rem;
  > i {
    color: inherit !important;
    font-size: 1rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
}
.btn-close {
  font-size: 1.40625rem;
  line-height: 1.5;
  transition: 0.3s color;
  background: 0 0 !important;
  padding: 0;
  font-weight: 700;
  color: #495057;
}
hr {
  background-color: inherit;
}
