/*------ Breadcrumb ------*/

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
  border-radius: 3px;
  font-weight: 400;
  background: transparent;
}
.breadcrumb-item {
  a {
    color: $primary;
  }
  + .breadcrumb-item {
    &::before {
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: #bcccda;
      content: '/';
      opacity: 0.5;
    }
    &:hover::before {
      text-decoration: underline;
      text-decoration: none;
    }
  }
  &.active {
    color: $color;
  }
}
.breadcrumb1 {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eaeefa;
  border-radius: 0.25rem;
}
.breadcrumb-item1 {
  a {
    color: $primary;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
  + .breadcrumb-item1 {
    &::before {
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      content: '/';
      opacity: 0.2;
    }
    &:hover::before {
      text-decoration: underline;
      text-decoration: none;
    }
  }
  &.active {
    color: $color;
  }
}
.breadcrumb-arrow {
  height: 36px;
  padding: 0;
  line-height: 36px;
  list-style: none;
  background-color: #eaeefa;
  li {
    &:first-child a {
      border-radius: 4px 0 0 4px;
      -webkit-border-radius: 4px 0 0 4px;
      -moz-border-radius: 4px 0 0 4px;
    }
    display: inline-block;
    vertical-align: top;
    a,
    span {
      display: inline-block;
      vertical-align: top;
    }
    &:not(:first-child) {
      margin-left: -5px;
    }
    + li:before {
      padding: 0;
      content: '';
    }
    span {
      padding: 0 10px;
    }
    a,
    &:not(:first-child) span {
      height: 36px;
      padding: 0 10px 0 25px;
      line-height: 36px;
    }
    &:first-child a {
      padding: 0 10px;
    }
    a {
      position: relative;
      color: $white;
      text-decoration: none;
      background-color: $primary;
      border: 1px solid $primary;
    }
    &:first-child a {
      padding-left: 10px;
    }
    a {
      &:after {
        position: absolute;
        top: -1px;
        width: 0;
        height: 0;
        content: '';
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
      }
      &:before {
        position: absolute;
        top: -1px;
        width: 0;
        height: 0;
        content: '';
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        right: -11px;
        z-index: 3;
        border-left-color: $primary;
        border-left-style: solid;
        border-left-width: 11px;
      }
      &:after {
        right: -12px;
        z-index: 2;
        border-left: 11px solid rgb(110, 121, 202);
      }
    }
    span {
      color: $color;
    }
  }
}
@media (max-width: 375px) {
  .breadcrumb {
    font-size: 12px;
  }
  .page-title {
    font-size: 20px !important;
  }
}
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  width: 25px;
  text-align: center;
  font-family: 'feather';
  line-height: 20px;
}
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  content: '\e92f';
}
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  content: '\e9d3';
}
.breadcrumb-style4 {
  .breadcrumb-icon {
    margin-top: 3px;
    margin-left: 4px;
  }
  .breadcrumb-item {
    .svg-icon {
      width: 20px;
    }
    &:first-child {
      margin-top: 0;
    }
    margin-top: 3px;
  }
}
.breadcrumb-item .svg-icon {
  color: $primary-09;
  fill: $primary-06;
}
