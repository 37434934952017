@import 'libs/ui-template/src/lib/styles/scss/variables';

$primary-rose-dark: var(--primary-bg-border);
$primary-rose-100: var(--primary-bg-color);
$primary-rose-75: #f3a2b8;
$primary-rose-50: #f7c1d0;
$primary-rose-25: #fbe0e7;
$primary-rose-15: #fdecf1;

$black-100: #464c54;
$black-85: #62676e;
$black-75: #74797f;
$black-50: #a2a5a9;
$black-25: #d1d2d4;
$black-20: #dadbdd;
$black-15: #e1e2e4;
$black-8: #f0f1f1;

$aquamarine: #84f0b6;
$aquamarine-15: #84f0b626;
$aquamarine-text: #70dea3;
$primary-rose-100-15: #ef83a126;
$black-100-5: #464c540d;

$background-calendar-active: #f4e4e9;
$custom-orange: #fbbb01;
$custom-green: #a8d81b;
$custom-blue: #91cfda;

$disabled-color: #e9ecef;

$b-radius-8: 8px;
$b-radius: 16px;
$b-radius-full: 50px;

// padding
$general-padding: 16px;
$general-padding-small: 8px;
$general-padding-x-small: 4px;

// margin
$general-margin: 16px;
$general-margin-small: 8px;
$general-margin-x-small: 4px;

// screen width
$lt-xs: 400px;
$lt-xs-1: 450px;
$lt-sm: 600px;
$lt-sb-sm-1: 700px;
$lt-sb-sm-2: 850px;
$lt-md: 960px;
$lt-sb-md: 992px;
$lt-sb-lg: 1240px;
$lt-lg: 1280px;
$lt-lg-1: 1500px;
$lt-xl: 1920px;

:root {
  --primary-bg-color: #ef83a1;
  --primary-bg-hover: #e07b98;
  --primary-bg-border: #e07b98;

  --primary-rose-25: #{$primary-rose-25};
}
