/*---- Tags-----*/

.tag {
  font-size: 0.75rem;
  color: $color;
  background-color: #ebeff8;
  border-radius: 3px;
  padding: 0 0.5rem;
  line-height: 2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s color, 0.3s background;
  &:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
  }
}
.tag-addon {
  display: inline-block;
  padding: 0 0.5rem;
  color: inherit;
  text-decoration: none;
  background: #e8ebf3;
  margin: 0 -0.5rem 0 0.5rem;
  text-align: center;
  min-width: 1.5rem;
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  i {
    vertical-align: middle;
    margin: 0 -0.25rem;
  }
}
a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s color, 0.3s background;
  &:hover {
    background: rgba(0, 0, 0, 0.16);
    color: inherit;
  }
}
.tag-avatar {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px 0 0 3px;
  margin: 0 0.5rem 0 -0.5rem;
}
.tag-blue {
  background-color: $blue;
  color: $white;
}
.tag-indigo {
  background-color: $indigo;
  color: $white;
}
.tag-purple {
  background-color: $purple;
  color: $white;
}
.tag-pink {
  background-color: $pink;
  color: $white;
}
.tag-red {
  background-color: #dc0441;
  color: $white;
}
.tag-orange {
  background-color: $orange;
  color: $white;
}
.tag-yellow {
  background-color: $warning;
  color: $white;
}
.tag-green {
  background-color: $success;
  color: $white;
}
.tag-teal {
  background-color: $teal;
  color: $white;
}
.tag-cyan {
  background-color: #17a2b8;
  color: $white;
}
.tag-white {
  background-color: $white;
  color: $white;
}
.tag-gray {
  background-color: #868e96;
  color: $white;
}
.tag-gray-dark {
  background-color: $dark;
  color: $white;
}
.tag-azure {
  background-color: $info;
  color: $white;
}
.tag-lime {
  background-color: #7bd235;
  color: $white;
}
.tag-primary {
  background-color: #467fcf;
  color: $white;
}
.tag-secondary {
  background-color: $secondary;
  color: $white;
}
.tag-success {
  background-color: $success;
  color: $white;
}
.tag-info {
  background-color: $info;
  color: $white;
}
.tag-warning {
  background-color: $warning;
  color: $white;
}
.tag-danger {
  background-color: #dc0441;
  color: $white;
}
.tag-light {
  background-color: #f8f9fa;
  color: $white;
}
.tag-dark {
  background-color: $dark;
  color: $white;
}
.tag-rounded {
  border-radius: 50px;
  .tag-avatar {
    border-radius: 50px;
  }
}
.tags {
  margin-bottom: -0.5rem;
  font-size: 0;
  > .tag {
    margin-bottom: 0.5rem;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
.tag.tag-attachments {
  padding: 4px 15px 2px 15px;
  font-size: 15px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}
.pro-img-box {
  position: relative;
}
.tag-outline-primary {
  color: #6259ca !important;
  border-color: #6259ca !important;
}

.tag-outline-secondary {
  color: $secondary !important;
  border-color: $secondary !important;
}

.tag-outline-success {
  color: $success !important;
  border-color: $success !important;
}

.tag-outline-warning {
  color: $warning !important;
  border-color: $warning !important;
}

.tag-outline-info {
  color: $info !important;
  border-color: $info !important;
}

.tag-outline-danger {
  color: $danger !important;
  border-color: $danger !important;
}
.attached-file-image .pic-1 {
  transition: all 0.3s ease;
}
.image-pic {
  position: absolute;
  right: 0;
  left: 0;
  top: 0px;
  color: #fff;
  font-size: 15px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 100%);
  padding: 10px 0px 20px 20px;
  border-radius: 5px;
}
.attached-file-grid6:hover .image-icons li {
  opacity: 1;
}
.attached-file-grid6:hover .image-icons {
  opacity: 1;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}
.attached-file-grid6 .image-icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  display: block;
  margin: 0 auto;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 100%);
}
.attached-file-grid6 .image-icons li {
  margin: 0 auto;
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease;
}
.attached-file-grid6 .image-icons li .btn {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: #fff;
  padding: 4px 8px;
}
.tag.tag-attachments-sm {
  padding: 3px 10px 1px 10px;
  font-size: 13px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}
.tag.tag-attachments-lg {
  padding: 5px 17px 3px 17px;
  font-size: 17px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}
.attached-file-grid6 .image-icons li .btn {
  color: $primary;
  border: 1px solid #fff;
}
.attached-file-grid6 .image-icons li .btn:active {
  color: $primary;
  border: 1px solid #fff;
}

.file-image-btn {
  width: 100px;
  height: 100px;
  display: inline-table;
  margin: 4px;
  position: relative;
  border: 1px solid #e9edf4;
  border-radius: 5px;
  margin-bottom: 20px;
}
.file-image-btn .product-image img {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.file-image-btn .image-icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  right: 7px;
  top: 6px;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}
.file-image-btn:hover .image-icons {
  opacity: 1;
  bottom: 33px;
}
.file-image-btn .image-icons .btn {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 4px 1px;
  padding: 0;
  border-radius: 50px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: #fff;
}
.file-image-btn .file-name-1 {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -22px;
  color: #524d4d;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}
.file-image-md {
  width: 150px;
  height: 150px;
}
.file-image-lg {
  width: 200px;
  height: 200px;
}
.file-image-lg .image-icons li a {
  font-size: 15px;
  line-height: 35px;
  height: 35px;
  width: 35px;
}
.file-image-md .image-icons li a {
  font-size: 13px;
  line-height: 30px;
  height: 28px;
  width: 28px;
}
.tags-attachment {
  font-size: 0.85rem;
  padding: 3px 15px 3px 15px;
  line-height: 2.3em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 4px;
  margin-bottom: 5px;
}
.tags-attachment.btn i {
  font-size: inherit;
  line-height: initial;
}
.tags-attachment-lg.btn i {
  font-size: inherit;
  line-height: initial;
}
.tags-attachment-sm.btn i {
  font-size: inherit;
  line-height: initial;
}
.tags-attachment-sm {
  font-size: 0.78rem;
  padding: 0.25rem 0.5rem;
  margin-right: 4px;
}
.tags-attachment-lg {
  font-size: 1.02rem;
  padding: 1px 12px 1px 12px;
  margin-right: 3px;
  line-height: 2.4;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.775rem;
  border-radius: 0.2rem;
}
