.pricing {
  color: $white;
  border-radius: 7px;
  .card-category {
    background: rgba(255, 255, 255, 0.34);
    padding: 7px 0;
    color: #fff;
    border-radius: 5px;
  }
  .list-unstyled li {
    border-bottom: 1px solid $white-1;
    padding: 7px 0;
  }
}

.pricing-table {
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  border-radius: 0px;
  &:hover {
    box-shadow: 0px 0px 30px $black-2;
    > {
      .panel-footer-landing {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) inset;
        -webkit-transition: all 0.3s ease;
      }
      .panel > {
        .panel-footer {
          background: #38a01e !important;
          -webkit-transition: all 0.3s ease;
          text-align: center;
          > .btn {
            border: solid 1px $white !important;
            -webkit-transition: all 0.3s ease;
          }
        }
      }
    }
  }
}

.table > {
  tbody > tr > th {
    font-weight: 500;
    -webkit-transition: all 0.3s ease;
  }
  tfoot > tr > {
    td,
    th {
      font-weight: 500;
      -webkit-transition: all 0.3s ease;
    }
  }
  thead > tr > {
    td,
    th {
      font-weight: 600;
      -webkit-transition: all 0.3s ease;
      outline: 0;
    }
  }
}

.col-sm-4 .pricing-table .table td {
  padding: 0.75rem;
  vertical-align: top;
  text-align: left;
}

.panel-title-landing {
  color: #38a01e !important;
  font-size: 35px;
  font-weight: bold;
}

.panel-footer-landing {
  border: solid 2px #38a01e !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top: none !important;
  text-align: center;
  background: #f7f7f7;
}
.plan-card {
  .plan-icon {
    display: inline-block;
    font-size: 35px;
    width: 100px;
    height: 100px;
    color: $white;
    line-height: 76px;
    overflow: hidden;
    border: 12px solid rgba(242, 246, 249, 0.55);
    border-radius: 50%;
    transition: all 0.3s;
  }
  .plan-price {
    border-top: $border;
    padding-top: 20px;
    span {
      font-size: 15px;
      vertical-align: middle;
    }
  }
  .plan-features p {
    line-height: 40px;
    margin-bottom: 0;
    border-bottom: $border;
    &:first-child {
      border-top: $border;
    }
  }
}
/*------ Pricing Styles ---------*/

.panel.price {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  > .panel-heading {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }
  h3 {
    margin-bottom: 0;
    padding: 20px 0;
  }
  .list-group-item {
    &:last-child {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
  margin-bottom: 1.5rem;
  border: $border;
  overflow: hidden;
  border-radius: 5px;
  overflow: hidden;
}
.panel-heading {
  padding: 10px 15px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: $white;
}
.panel.price > .panel-heading {
  background: $primary;
  color: $white;
}
.price .list-group-item {
  border-bottom: 1px solid rgba(250, 250, 250, 0.5);
}
.panel.price {
}
.price {
  .panel-footer {
    border-bottom: 0px;
    background-color: $white;
  }
  &.panel-color > .panel-body {
    background-color: $white;
  }
}
.panel-body {
  padding: 15px;
  .lead {
    strong {
      font-size: 40px;
      margin-bottom: 0;
    }
    font-size: 20px;
    margin-bottom: 0;
    padding: 10px 0;
  }
}
.panel-footer {
  padding: 10px 15px;
  background-color: #f7f7f7;
  border-top: $border;
  border-left: $border;
  border-right: $border;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel.price .btn {
  box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
  border: 0px;
}
/*end of pricing*/

@media (min-width: 992px) {
  .plan-card.active {
    margin-top: -25px;
    margin-left: -20px;
    width: 120%;
    height: 100%;
    z-index: 1;
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px rgba(176, 184, 214, 0.16);
    padding: 30px 0;
    font-size: 16px;
    padding-bottom: 20px;
    .plan-features p {
      line-height: 50px;
    }
    .plan-card .plan-price span {
      font-size: 18px;
    }
    .display-4 {
      font-size: 3.5rem;
    }
  }
  .pricing-sctive .display-3 {
    font-size: 4rem;
  }
  .plan-card.active h6 {
    font-size: 16px;
  }
  .pricing-sctive {
    margin-top: -25px;
    margin-left: -20px;
    width: 120%;
    height: 100%;
    font-size: 16px;
    z-index: 1;
  }
  .panel.price.active {
    margin-top: -25px;
    margin-left: -20px;
    width: 120%;
    height: 100%;
    z-index: 8;
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px rgba(176, 184, 214, 0.08);
    position: relative;
    font-size: 16px;
    border-right: 1px !important;
  }
  .no-gutters .panel.price {
    &.active {
      .panel-body .lead strong {
        font-size: 50px;
      }
      .price-title {
        font-size: 26px;
      }
    }
    border-right: 0;
    &:last-child {
      border-right: 1px;
    }
  }
}
.price-svg {
  position: relative;
  .price-title {
    position: absolute;
    top: 29%;
    left: 0;
    right: 0;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 24px;
  }
  svg {
    height: 120px;
  }
}
.pricing-tabs ul.nav-price {
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  margin-top: 30px;
  margin-bottom: 40px;
  border: $border;
  border-radius: 5px;
  background: $white;
  overflow: hidden;
  li {
    display: block;
    a {
      padding: 12px 40px;
      display: block;
      background: $white;
      font-size: 16px;
      /* color: #14112d; */
      border-radius: 0px;
      transition: 0.3s;
      &.active {
        background: $primary;
        color: $white;
        transition: 0.3s;
      }
    }
  }
}
.leading-loose li {
  padding: 5px 0;
  &:last-child {
    border-bottom: 0;
  }
}
.pricing-tabs {
  .pri-tabs-heading2 ul.nav-price {
    text-align: center;
    margin: 0 auto;
    display: inline-flex;
    margin-top: 30px;
    margin-bottom: 40px;
    border: 1px solid #d3dfea;
    border-radius: 0px;
    background: #eaeefa;
    border-radius: 50px;
    li {
      display: block;
      a {
        padding: 12px 40px;
        display: block;
        background: #eaeefa;
        font-size: 16px;
        /* color: #14112d; */
        border-radius: 50px;
        transition: 0.3s;
        font-weight: 600;
        &.active {
          background: $primary;
        }
      }
    }
  }
  .pri-tabs-heading3 ul.nav-price {
    border-radius: 50px;
    li {
      &:first-child a {
        border-radius: 50px 0 0 50px;
        border-left: 0;
      }
      &:last-child a {
        border-radius: 0 50px 50px 0;
      }
      a {
        border-left: $border;
      }
    }
  }
}
