@import 'ui-template';
@import 'fonts';
@import 'mixins';
@import 'sidebar';
@import 'buttons';

* {
  font-family: 'Inter', sans-serif;
}

body {
  color: $black-100;
  background-color: $black-8;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Inter', sans-serif;
}

a {
  color: $primary-rose-100;
}

ul:not(.side-menu, .slide-menu) {
  list-style-type: disc;
  padding-left: #{2 * $general-margin};
  margin-bottom: $general-margin;
}

.dropdown-menu ul,
ul.pagination {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.table th,
.text-wrap table th,
.alert-warning,
.dropdown-menu,
.dropdown-item {
  color: $black-100;
}

.table th,
.table > :not(:first-child),
.table thead th {
  border: 0;
}

.table > thead > tr > th {
  text-transform: none;
}

.flex-1 {
  flex: 1;
}

.page-layout {
  @include flex-column();
  padding: 0 #{4 * $general-padding-small} 0 #{$general-padding + $general-padding-x-small};
}

.page-layout-sub {
  @include flex-row();
  margin: $general-margin-small 0;
  padding: $general-padding-small;
  background-color: $white;
  border-radius: $b-radius;
}

.border-r-16 {
  border-radius: $b-radius;
}

.border-r-full {
  border-radius: $b-radius-full;
}

.activity-title {
  color: $black-50;
  @include font-medium();
}

// Form
form {
  .form-group {
    margin-bottom: #{3 * $general-margin-small};
  }

  .row .form-group:last-of-type {
    margin-bottom: 0;
  }

  .form-control-lg,
  label {
    font-family: 'Inter', sans-serif;
  }

  label {
    @include font-medium();
    font-size: 14px;
  }

  .form-control-lg {
    padding: #{3 * $general-padding-x-small $general-padding};
    font-size: 15px;
  }

  .form-control {
    color: $black-100;
    border-color: $black-20;

    &:focus {
      border-color: $primary-rose-dark;
      box-shadow: none;
    }
  }

  .input-group {
    .form-control {
      border-right: 0;
      padding: #{3 * $general-padding-x-small} 0 #{3 * $general-padding-x-small} $general-padding;
    }
  }

  .input-group-append {
    height: calc(1.5em + 0.75rem + 16px);

    .btn {
      border-left: 0;
      padding: #{3 * $general-padding-x-small} $general-padding #{3 * $general-padding-x-small} $general-padding-x-small;
      border-radius: 0 $b-radius-8 $b-radius-8 0;
      border-color: $black-20;
    }

    img {
      width: 20px;
      margin-bottom: #{$general-margin-x-small + 2};
    }
  }

  .input-group .form-control:focus + .input-group-append .btn {
    border-color: $primary-rose-dark;
  }

  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-left-radius: $b-radius-8;
    border-bottom-left-radius: $b-radius-8;
  }

  .input-group {
    .form-control.is-invalid + .input-group-append .btn {
      border-color: #dc3545;
      border-top-right-radius: $b-radius-8;
      border-bottom-right-radius: $b-radius-8;
    }

    .form-control.is-invalid:focus,
    .form-control.is-invalid:focus + .input-group-append .btn {
      box-shadow: none;
    }
  }
}

.ng-select {
  padding: #{$general-padding-x-small + 1} $general-padding;
  border-radius: $b-radius-8;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 0;
}

.ng-select {
  .ng-select-container {
    max-width: 306px;
    z-index: 99 !important;

    .ng-value-container,
    .ng-value-container .ng-placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 15px;
      @include font-regular();
    }

    .ng-value-container {
      padding-left: 0;
    }

    .ng-value-container .ng-placeholder {
      color: $black-50;
    }

    .ng-value {
      color: $black-100;
    }

    .ng-arrow-wrapper {
      padding-right: 0;
    }

    .ng-arrow-wrapper .ng-arrow {
      border: 0;
      background: url('assets/img/arrow-down.svg') no-repeat center center;
      background-size: cover;
      width: 12px;
      height: 8px;
    }
  }

  &.ng-select-disabled {
    background-color: $disabled-color;

    .ng-select-container {
      background-color: $disabled-color !important;
    }
  }
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: $white;
}

ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  transform: rotate(180deg);
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: none;
}

.ng-select.ng-select-focused:not(.ng-select-opened) {
  border-color: $primary-rose-dark;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: break-spaces !important;
}

.ng-dropdown-panel {
  z-index: 100 !important;
  font-size: 14px;
  padding: #{$general-padding-x-small - 2};
}

ngb-datepicker {
  z-index: 100 !important;
}

// Modal
.modal {
  .btn {
    border-radius: $b-radius-8;
  }

  .modal-body {
    ng-select {
      width: 100%;
      min-width: 300px;
      border: 2px solid $black-15;

      .ng-select-container {
        max-width: none;
      }
    }
  }

  .modal-footer {
    .btn-primary {
      padding: $general-padding-small $general-padding;
    }

    .btn-lg {
      padding: #{3 * $general-padding-x-small} #{3 * $general-padding-small};
    }
  }
}

input[type='checkbox']:not([role='switch']) {
  &.form-check-input {
    width: 24px;
    height: 24px;
    margin-top: 0;
    margin-right: #{3 * $general-margin-x-small};
    @include pointer();
  }

  &.form-check-label {
    @include pointer();
    @include font-regular();
    font-size: 15px;
  }

  &.form-check-input:checked {
    background-color: $primary-rose-100;
    border-color: $primary-rose-100;
  }

  &.form-check-input:focus {
    border-color: $primary-rose-dark;
    box-shadow: none;
  }

  &.form-check-input:indeterminate {
    background-color: $primary-rose-100;
    border-color: $primary-rose-100;
  }

  &.indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }
}

.form-switch {
  .form-check-input {
    width: 48px;
    height: 24px;
    border: none;
    margin-top: 0;
    margin-right: $general-margin-small;
    @include pointer();
    color: $primary-rose-100;
    background-color: $black-15;
    background-image: url('assets/img/x.svg'),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-size: 50% 82.5%;

    &:focus {
      box-shadow: none;
      background-image: url('assets/img/x.svg'),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    &:checked {
      background-color: $primary-rose-100;
      background-image: url('assets/img/check.svg'),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
  }

  .form-check-label {
    margin-bottom: 0;
  }
}

// Table
.table-wrapper {
  .table {
    margin-bottom: #{3 * $general-margin};
  }

  tr {
    display: flex;
    align-items: center;
    padding: $general-padding #{2 * $general-padding};
    border-bottom: 1px solid $black-15;
  }

  th,
  td {
    flex: 1;
    padding: $general-padding-small 0;
  }

  th {
    @include font-regular();
    font-size: 13px;
    color: $black-50;
  }

  th:last-of-type,
  td:last-of-type {
    flex: 0;

    button {
      width: 28.91px;
      color: $primary-rose-dark;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        @include font-bold();
        font-size: 20px;
        line-height: 1.4;
      }

      &.btn:first-child:active {
        color: $primary-rose-100;
      }
    }
  }

  thead tr {
    height: 45px;

    th {
      @include font-regular();
    }
  }

  td {
    font-size: 15px;
  }

  tbody tr {
    transition: background-color 0.1s linear;
  }

  tbody tr:hover {
    background-color: $primary-rose-15;
    @include pointer();

    .btn {
      background-color: $white;
    }
  }

  .no-results {
    pointer-events: none;

    td {
      flex: 1;
    }

    img {
      filter: grayscale(0.5);
      opacity: 0.8;
    }
  }
}

// Pagination
ngb-pagination {
  .page-link {
    border-radius: $b-radius-full;
    margin-left: #{0.5 * $general-margin};
    font-size: 12px;
    @include font-bold();
    color: $black-100;
    background-color: transparent;
    border-color: $black-15;
  }

  .page-item:not(:first-child) .page-link {
    margin-left: #{0.5 * $general-margin};
  }

  .page-item:first-child .page-link,
  .page-item:last-child .page-link {
    border-radius: $b-radius-full;
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
  }

  .page-item.disabled .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color);
  }
}

isophi-toasts {
  .toast-body {
    max-height: 50vh;
    overflow-y: auto;
  }

  ngb-toast {
    .toast-header {
      height: 25px;
      background-color: $danger;
    }

    .btn-close {
      width: 12px;
      height: 25px;
      filter: brightness(0) invert(1);
      opacity: 0.75;
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat !important;
    }
  }
}

.sidebar-mini.sidenav-toggled.sidenav-toggled1 .app-sidebar {
  .btn {
    padding-left: #{9 * $general-padding-x-small};
    text-align: left;
  }
}

.sidebar-mini.sidenav-toggled .side-menu {
  .btn {
    text-align: center;
    padding-left: 0;
  }

  @media screen and (max-width: $lt-sb-md) {
    .btn {
      padding-left: #{9 * $general-padding-x-small};
      text-align: left;
    }
  }
}

@media (min-width: 676px) {
  .modal-md {
    max-width: 504px;
  }
}

@media screen and (max-width: $lt-md) {
  .app-content {
    margin-top: 125px !important;

    .side-app {
      padding: 0;
    }
  }

  .page-layout {
    padding: 0 $general-padding 0 $general-padding;
  }

  .ng-select {
    .ng-select-container {
      max-width: initial;
    }

    .ng-arrow-wrapper {
      text-align: end;
    }

    .ng-value-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      display: inline-block;
      margin-top: $general-margin-x-small;
    }
  }
}

@media (min-width: 876px) {
  .modal-lg {
    max-width: 680px;
  }
}

@media print {
  body {
    background-color: $white;
    width: 297mm;
    height: 210mm;
  }

  .main-content {
    margin-left: 0;
  }
}
